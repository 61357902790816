<template>
  <section>
    <v-img
      :src="baseUrl + 'banner.jpg'"
      class="top-banner-home"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/service/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container grid-list-md text-center class="ctk-div" style="padding-top: 0px !important;">
      <v-container
        fluid
        grid-list-sm
      >
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="ctk-color-title">“色彩之旅”趋势服务——RENOLIT的色彩和设计服务</v-flex>
          <v-flex xs12 sm12 md8 lg8>
            <p class="ctk-color-p" style="margin-bottom: 0;"> 通过RENOLIT的“色彩之旅”趋势服务，我们利用色彩专家和趋势研究机构的全球网络，创建针对市场和客户的趋势报告。</p>
            <p class="ctk-color-p" style="margin-bottom: 20px;">通过这份趋势报告以及相关的趋势故事和调色板，我们为客户提供现代产品开发的灵感和保障。通过我们的全球趋势咨询，使我们的客户能够凭借其产品和营销策略在竞争对手中脱颖而出，从而为他们的长期经济成功做出贡献。</p>
            <v-img :src="baseUrl + '1.jpg'"></v-img>
            <v-img :src="baseUrl + '2.jpg'" style="margin: 40px 0;"></v-img>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4></v-flex>
          <v-flex xs12 sm12 md5 lg5 class="fl">
            <h4 class="ctk-color-subtitle">什么是“色彩之旅”趋势服务？</h4>
            <p class="ctk-color-p">通过“色彩之旅”趋势服务，我们为国际客户开发成功的产品和营销理念提供支持。特别是在瞬息万变的世界中，人们往往缺乏时间和专业知识来监测和识别最新的色彩和设计趋势。请观看我们的趋势服务宣传片，了解如何从我们的咨询中获益。</p>
          </v-flex>
          <v-flex xs12 sm12 md7 lg7 class="ctk-color-video">
            <video id="player1" controls :poster="baseUrl + 'video/1.png'">
              <source :src="baseUrl + 'video/2.mp4'" type="video/mp4" />
            </video>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <h3  xs12 sm12 md12 lg12 class="ctk-color-title" style="margin-top: 60px;">Colour Road 24/25 - Treasures of our planet</h3>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="margin-bottom: 30px;">
            <h4 class="ctk-color-subtitle">色彩之旅24/25——地球的宝藏</h4>
          </v-flex>
          <v-flex xs12 sm12 md8 lg8 class="ctk-color-video">
            <video id="player2" controls :poster="baseUrl + 'video/2.png'">
              <source :src="baseUrl + 'video/2.mp4'" type="video/mp4" />
            </video>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 class="fr">
            <p class="ctk-color-p">你在地球上的哪些地方发现了宝藏？这些宝藏在你的日常生活中扮演着怎样的角色？</p>
            <p class="ctk-color-p">听听我们询问的一些人的答案。</p>
            <p class="ctk-color-p">你的宝藏是什么？</p>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 class="fl">
            <p class="ctk-color-p">沼泽是令人惊叹的生态系统。在我们与荒野向导Andrea Wissel制作的趋势影片中，一起探索有关沼泽的激动人心的事实。欣赏视频！</p>
          </v-flex>
          <v-flex xs12 sm12 md8 lg8 class="ctk-color-video">
            <video id="player3" controls :poster="baseUrl + 'video/3.png'">
              <source :src="baseUrl + 'video/3.mp4'" type="video/mp4" />
            </video>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <h4 class="ctk-color-title" style="margin-top: 50px;">趋势主题 2024/2025</h4>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <p class="ctk-color-p">“色彩之旅24/25”的主题从 "地球的宝藏 "中汲取灵感，让我们意识到我们世界的宝藏是如此多样，又是如此短暂。保护这些资源和宝藏比以往任何时候都更加重要。</p>
            <p class="ctk-color-p">有时隐藏在地球深处的重要矿物质、稀土和特殊元素，即使数量极少，也能在我们的日常生活中发挥重要作用。地球表面丰富多彩的地貌和地理结构令人叹为观止，也造就了地球上生命的多样性。我们一直着迷于天空的现象，观察云和研究风。</p>
            <p class="ctk-color-p">正如爱因斯坦所言："深入观察大自然，你将更好地理解一切。"</p>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <v-carousel
              class="ctk-color-ps"
              cycle
              hide-delimiter-background
              hide-delimiters
              :show-arrows="true"
              width="100%"
              height="auto"
            >
              <v-carousel-item
                v-for="(color, ci) in colors"
                :key="ci"
                :src="baseUrl + 'color/' + color + '.jpg'"
              ></v-carousel-item>
            </v-carousel>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="ctk-color-title" style="margin-top: 30px;">流行色彩——专注室内空间设计</v-flex>
          <v-flex xs12 sm12 md8 lg8>
            <p class="ctk-color-p">与国际时尚机构紧密合作，获取全球色彩潮流并将其转化为面向市场的色彩组合。请您关注“色彩之旅”，发现室内空间设计领域的新色彩潮流！</p>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="middle-img">
            <v-img :src="baseUrl + 'middle.jpg'"></v-img>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="ctk-color-title">趋势画册</v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <flipbook
              class="flipbook"
              :pages="pages"
              :pagesHiRes="pagesHiRes"
              :startPage="pageNum"
              :singlePage="true"
              :clickToZoom="false"
              v-slot="flipbook"
              ref="flipbook"
            >
              <div class="book-action-bar">
                <left-icon
                  class="btn left"
                  :class="{ disabled: !flipbook.canFlipLeft }"
                  @click="flipbook.flipLeft"
                />
                <plus-icon
                  class="btn plus"
                  :class="{ disabled: !flipbook.canZoomIn }"
                  @click="flipbook.zoomIn"
                />
                <span class="page-num">
                  {{ flipbook.page }} / {{ flipbook.numPages }}
                </span>
                <minus-icon
                  class="btn minus"
                  :class="{ disabled: !flipbook.canZoomOut }"
                  @click="flipbook.zoomOut"
                />
                <right-icon
                  class="btn right"
                  :class="{ disabled: !flipbook.canFlipRight }"
                  @click="flipbook.flipRight"
                />
              </div>
            </flipbook>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="ctk-color-title" style="margin-bottom: 20px;">产品与服务</v-flex>
          <v-flex xs12 sm12 md8 lg8 style="text-align: left;">
            <a href="/products">请点击查看相关信息</a>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="ctk-color-title">联系方式</v-flex>
          <v-flex xs12 sm12 md8 lg8 style="margin-bottom: 80px;">
            <v-layout wrap>
              <v-flex xs12 sm12 md4 lg4>
                <v-img :src="baseUrl + 'lxr.jpg'" max-height="334" contain class="ctk-color-avatar"></v-img>
              </v-flex>
              <v-flex xs12 sm12 md8 lg8 class="ctk-color-lxr">
                <h3>沈晓莲</h3>
                <p>
                  德硅集团品牌总监
                  雷诺丽特“色彩之旅”全球色彩大使<br/>
                  CMG亚太区色彩预测引导师<br/>
                  Mob.: 13301818601（同微信）<br/>
                  Tel.: 021-54234098-969<br/>
                  Email:xiaolian.shen@citiking.com.cn<br/>
                </p>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
import 'vue-material-design-icons/styles.css'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from 'flipbook-vue'
export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  data: () => ({
    pageId: 5,
    baseUrl: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/color-2024/',
    tabSubMenu: null,
    subMenu: null,
    colors: ['01', '02', '03'],
    pageNum: null,
    pages: [ null ],
    pagesHiRes: [ null ]
  }),
  created () {
    document.querySelector('#menu_service').classList.add('v-btn--active')
    this.tabSubMenu = 1
    this.getPageData()
    // this.getColor()
    for (let i = 1; i <= 38; i++) {
      let num = i < 10 ? '0' + i : i
      let img = this.baseUrl + 'brochure/' + num + '.jpg'
      this.pages.push(img)
    }
    this.pagesHiRes = this.pages
  },
  mounted () {
    // 书高度
    // let width = document.querySelector('.flipbook').offsetWidth
    // this.bookHeight = width * 1241 / 1654 + 30 + 'px'
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        // this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.subMenu = this.$t('service.subMenu')
    },
    getColor () {
      this.https.get('color').then(response => {
        if (response.code === 0) {
          this.color = response.data
        }
      })
    }
  }
}
</script>
<style>
  .ctk-color-title {
    text-align: left;
    font-size:32px;
    font-weight:500;
    color:rgba(51,51,51,1);
    margin-top: 80px;
    margin-bottom: 30px;
  }
  .ctk-color-subtitle {
    text-align: left;
    font-size:24px;
    font-weight:500;
    color:rgba(51,51,51,1);
  }
  .ctk-color-p {
    text-indent: 2em;
    text-align: left;
    font-size:16px;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:32px;
  }
  .ctk-color-video {
    padding: 0;
    margin:0;
    margin-bottom: 30px;
  }
  .ctk-color-video video {
    width: 100%;
  }
  .ctk-color-ps .v-image__image--cover {
    background-size: contain;
  }
  .ctk-color-zt .v-image__image--cover {
    background-size: contain;
  }
  .ctk-color-lxr {
    margin: 0 auto;
    padding-left: 40px !important;
  }
  .ctk-color-lxr h3{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }
  .ctk-color-lxr p{
    font-size: 16px;
    line-height: 40px;
    text-align: left;
  }
  .ctk-color-zt {
    height: 529px;
  }
  .fr {
    padding-left: 38px !important;
  }
  .fl {
    padding-right: 38px !important;
  }
  @media(min-width:960px){
    .ctk-color-zt {
      height: 200px !important;
      min-height: 200px !important;
    }
  }
  .ctk-color-avatar .v-image__image--contain {
    background-position: left center !important;
  }
  .book-action-bar {
    width: 100%;
    height: 30px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .book-action-bar .btn {
    font-size: 30px;
    color: #666;
  }
  .book-action-bar .btn svg {
    bottom: 0;
  }
  .book-action-bar .btn:not(:first-child) {
    margin-left: 10px;
  }
  .book-action-bar .btn:hover {
    color: #ccc;
    filter: drop-shadow(1px 1px 5px #000);
    cursor: pointer;
  }
  .book-action-bar .btn:active {
    filter: none !important;
  }
  .book-action-bar .btn.disabled {
    color: #999;
    pointer-events: none;
  }
  .book-action-bar .page-num {
    font-size: 12px;
    margin-left: 10px;
  }
  .flipbook {
    width: 100%;
    height: calc(90vh + 50px);
  }
  .flipbook .viewport {
    margin-top: 20px;
    /* width: 100%; */
    /* height: calc(100vh - 50px - 40px); */
    /* margin: 0 auto; */
  }
  .flipbook .bounding-box {
    /* box-shadow: 0 0 20px #000; */
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #CCC;
  }
  .middle-img {
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #CCC;
  }
  @media(max-width:960px){
    .flipbook {
      width: 100%;
      height: 80vh;
    }
    .ctk-color-title {
      font-size:24px;
      font-weight:500;
      margin-top: 80px;
      margin-bottom: 30px;
    }
    .ctk-color-subtitle {
      font-size:18px;
      font-weight:500;
    }
    .ctk-color-p {
      font-size:14px;
      font-weight:400;
    }
    .ctk-color-video {
      margin-bottom: 20px;
    }
    .ctk-color-lxr {
      padding-left: 0 !important;
    }
    .ctk-color-lxr h3{
      font-size: 16px;
      font-weight: 500;
    }
    .ctk-color-lxr p{
      font-size: 14px;
    }
    .fr {
      padding-left: 0 !important;
    }
    .fl {
      padding-right: 0 !important;
    }
  }
</style>
